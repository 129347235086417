<template>
    <div>
        <p>
            <span class="text-danger font-weight-bold">NOTE : </span>
            <span>
                Please ensure to answer all questions and do not leave or refresh
                the page before interview is completed.
            </span>
        </p>
        <div class="card fl-gr-1 p-2 fl-y" ref="messageContainer">
            <chat-bubble
                :key="index"
                v-for="( message, index) in messages"
                :role="message.role"
                :caption="message.caption"
                :message="message.message"
            />
            <div v-if="loading" class="card text-center m-3">
                <loading-animation/>
                <div>Please wait while the answer is being evaluated.</div>
            </div>
        </div>
        <template v-if="!interviewCompleted && !interviewCancelledOrCompleted">
            <div class="card" v-if="messages[messages.length - 1].role !== 'comment'">
                <validated-text-area :disabled="loading" @keyup.enter.ctrl="sendAnswer" v-model="answer"
                                     textarea-classes="min-h-8r" name="answer"/>
                <div class="text-right">
                    <btn title="Submit" @click="sendAnswer" :disabled="loading"
                         :loading="loading" loading-text="Submitting"/>
                </div>
            </div>
        </template>
        <div v-if="interviewCompleted" class="text-center mt-4">
            <btn title="Results" @click="viewResults"/>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import ChatBubble from '../../../components/ChatBubble.vue';
import urls from '../../../data/urls';

export default {
    name: 'Interview',
    components: {
        ChatBubble
    },
    data () {
        return {
            loading: false,
            interviewCompleted: false,
            interviewCancelledOrCompleted: false,
            interviewId: this.$route.params.id,
            answer: '',
            messages: []
        };
    },
    mounted () {
        this.startInterview();
    },
    beforeRouteLeave (to, from, next) {
        if (!this.interviewCompleted && !this.interviewCancelledOrCompleted) {
            const answer = window.confirm('You haven’t finished the interview yet. Are you sure you want to leave?');
            if (answer) {
                this.stopInterview();
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    },
    methods: {
        async stopInterview () {
            try {
                const data = {
                    interview_id: this.interviewId,
                    status: 'Cancelled'
                };
                const response = await axios.form(urls.interview.changeStatus, data);
                console.log('response', response);
                const json = response.data;

                if (json.success === true) {
                    this.$notify(json.message, 'Interview Cancelled', {
                        type: 'warning'
                    });
                } else {
                    this.$notify('Could not cancel the interview. Please try again later.', 'Error', {
                        type: 'danger'
                    });
                }
            } catch (error) {
                console.error(error);
                this.$notify('An error occurred while cancelling the interview.', 'Error', {
                    type: 'danger'
                });
            }
            this.doScroll();
        },

        async startInterview () {
            try {
                const data = { interview_id: this.interviewId };
                const response = await axios.form(urls.interview.questions, data);

                const questions = response.data.questions;
                const interview = response.data.interview;
                this.interviewCompleted = questions[questions.length - 1].answered;
                this.interviewCancelledOrCompleted = interview.status === 'Completed' || interview.status === 'Cancelled';
                if (this.interviewCancelledOrCompleted) {
                    this.viewResults();
                }
                // Loop questions
                for (let i = 0; i < questions.length; i++) {
                    const question = questions[i];
                    this.messages.push({
                        caption: 'Question',
                        role: 'question',
                        message: question.question
                    });

                    const answered = question.answered;
                    if (answered) {
                        this.messages.push({
                            role: 'answer',
                            message: question.answer
                        });

                        this.messages.push({
                            role: 'comment',
                            message: question.comment
                        });
                    }
                }
            } catch (error) {
                console.error(error);
            }
            this.doScroll();
        },

        doScroll () {
            const messageContainer = this.$refs.messageContainer;
            setTimeout(() => {
                const lastChild = messageContainer.lastElementChild;
                if (lastChild) {
                    lastChild.scrollIntoView({ behavior: 'smooth' });
                }
            }, 200);
        },

        async sendAnswer () {
            this.loading = true;
            try {
                const answer = this.answer;

                const data = {
                    interview_id: this.interviewId,
                    answer
                };
                const response = await axios.form(urls.interview.answer, data);
                this.messages.push({
                    role: 'answer',
                    message: answer
                });

                const obj = response.data;

                const completed = obj.completed;

                this.messages.push({
                    role: 'comment',
                    message: obj.comment
                });

                if (completed) {
                    this.interviewCompleted = true;
                    this.messages.push({
                        role: 'completion',
                        message: 'Interview has been completed'
                    });
                } else {
                    this.messages.push({
                        caption: 'Question',
                        role: 'question',
                        message: obj.question
                    });
                }
            } catch (error) {
                console.error(error);
            }

            this.answer = '';
            this.loading = false;
            this.doScroll();
        },

        viewResults () {
            this.$router.push('/interview/' + this.interviewId + '/details/');
        }
    }
};
</script>

<style scoped>

</style>
