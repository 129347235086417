<template>
    <div class="card bs-4 pt-2">
        <div class="card-body">
            <h5 class="card-title" v-html="title"></h5>
            <div class="card-text description fs--1 thin-scrollbar" @click="showDescription"
                 @mouseover="showScrollbar(index)" @mouseleave="hideScrollbar(index)"
                 :class="{ 'show-scrollbar': scrollIndex === index }">
                <slot name="body"></slot>
            </div>
            <div class="fl-x-cc mt-2">
                <slot name="buttons"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DetailsCard',
    props: {
        title: {
            type: String, default: ''
        },
        data: {
            type: Object, default: null
        },
        index: {
            type: Number, default: 0
        }
    },
    data () {
        return {
            // scroll related variables
            scrollIndex: null,
            hoverTimeout: null
        };
    },
    methods: {
        showDescription () {
            this.$emit('showDescription', this.data);
        },
        showScrollbar (index) {
            this.hoverTimeout = setTimeout(() => {
                this.scrollIndex = index;
            }, 2000);
        },
        hideScrollbar () {
            clearTimeout(this.hoverTimeout);
            this.scrollIndex = null;
        }
    }
};
</script>

<style scoped>
.description {
    cursor: pointer;
    min-height: 6.2rem;
    max-height: 6.2rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
}

.description::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.description::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.description::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}</style>
