<template>
    <div>
        <admin-home v-if="currentUser.is_superuser"></admin-home>
        <user-home v-else></user-home>
    </div>
</template>

<script>
import AdminHome from './admin/AdminHome';
import UserHome from './user/UserHome';
import { mapGetters } from 'vuex';

export default {
    name: 'HomePage',
    components: { UserHome, AdminHome },
    computed: {
        ...mapGetters(['currentUser'])
    }
};
</script>

<style scoped>

</style>
