<template>
    <div class="container">
        <h4 class="p-0 text-primary">Interviews</h4>

        <!-- Loading state -->
        <div class="m-5 mt-7 text-center" v-if="dataLoading">
            <loading-animation></loading-animation>
            <br/>
            <div>Please wait while templates are being loaded...</div>
        </div>

        <!-- Details Cards for each template -->
        <div class="row" v-else-if="!dataLoading && data.data.length !== 0">
            <div v-for="(interview, index) in data.data" :key="index" class="col-12 col-md-6 col-lg-3 mt-4">
                <details-card :data="interview" :title="interview.name" :index="index"
                              @showDescription="showDescription">
                    <template #body>
                        <div class="bold">No. of Questions :
                            <span class="text-muted">{{ interview.number_of_questions }}</span>
                        </div>
                        <div class="mt-1">Description :
                            <span class="text-muted">{{ interview.description }}</span>
                        </div>
                    </template>
                    <template #buttons>
                        <btn size="sm" @click="attendInterview(interview)"
                             class="btn-block" title="Start Interview"/>
                    </template>
                </details-card>
            </div>
        </div>

        <div v-else-if="!dataLoading && data.data.length===0" class="text-center align-vertically-center">
            Interviews are unavailable at the moment.
        </div>

        <modal :title="getTitle" ref="detailsModal" width="35r" header-color="primary" no-close-on-backdrop>
            <div class="container">
                <div class=" mt-1">
                    <span>Description : </span> <span class="text-muted">{{ details.description }}</span>
                </div>
                <div class="mt-1">
                    <span>Number of Questions : </span>
                    <span class="text-muted">
                        {{ details.number_of_questions }}
                    </span>
                </div>
                <div class="text-right">
                    <btn size="sm" @click="attendInterview(details)"
                         class="mt-3" title="Start Interview"/>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import DetailsCard from '../../common-components/DetailsCard';

export default {
    name: 'Interviews',
    components: { DetailsCard },
    computed: {
        ...mapGetters(['currentUser']),
        getTitle () {
            return 'Topic : ' + this.details.name;
        }
    },
    data () {
        return {
            scrollIndex: null,
            hoverTimeout: null,

            attending: false,
            attendingInterviewIndex: '',
            dataLoading: false,
            data: [],
            details: {},
            listUrl: urls.interview.listTemplate,
            addEditUrl: urls.interview.createTemplate,
            deleteUrl: urls.interview.deleteTemplate,
            editingItem: null,
            deletingItem: null
        };
    },
    mounted () {
        this.loadInterviews();
    },
    methods: {
        async loadInterviews () {
            this.dataLoading = true;
            const response = await axios.get(this.listUrl, {
                params: {
                    user: this.currentUser.username
                }
            });
            this.data = response.data;
            this.dataLoading = false;
        },

        async attendInterview (item, index) {
            this.attending = true;
            this.attendingInterviewIndex = index;
            try {
                const data = { template_id: item.id };
                const response = await axios.form(urls.interview.attend, data);
                const json = response.data;
                if (json.success === true) {
                    this.startInterview(json.interview_id);
                } else {
                    this.$notify('Could not attend the interview.Please try again later.', 'Failed', {
                        type: 'danger'
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.attending = false;
            }
            this.doScroll();
        },

        startInterview (id) {
            this.$refs.detailsModal.close();
            this.$router.push('/user/interview/' + id + '/start/');
        },

        showScrollbar (index) {
            this.hoverTimeout = setTimeout(() => {
                this.scrollIndex = index;
            }, 2000);
        },
        hideScrollbar () {
            clearTimeout(this.hoverTimeout);
            this.scrollIndex = null;
        },
        showDescription (item) {
            this.details = { ...item };
            this.$refs.detailsModal.show();
        }
    }
};
</script>

<style scoped>
.align-vertically-center {
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    min-height: 60vh; /* Set height to full viewport or container height */
}
</style>
