<template>
    <div class="pb-0" :class="parentClass" :data-role="role">
        <b v-if="caption" v-text="caption"></b>
        <span class="d-block" v-html="message"/>
    </div>
</template>

<script>
export default {
    name: 'ChatBubble',
    props: {
        message: {
            type: String,
            required: true
        },
        role: {
            type: String,
            default: 'question'
        },
        caption: {
            type: String,
            default: ''
        }
    },
    computed: {
        parentClass () {
            const classes = [];
            if (this.role === 'completion') {
                classes.push('bg-info mt-4 text-center p-3 round-3');
                classes.push('fl-x');
            } else if (this.role === 'question') {
                classes.push('bg-3 mt-5 round-2 p-3');
            } else if (this.role === 'comment') {
                classes.push('ml-4');
                classes.push('text-info font-weight-bold');
            } else if (this.role === 'correct-answer') {
                classes.push('ml-4');
                classes.push('text-success font-italic');
            } else if (this.role === 'answer') {
                classes.push('ml-4 mt-2');
            }

            return classes;
        }
    }
};
</script>
