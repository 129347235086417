<template>
    <div>
        User Home Page
    </div>
</template>

<script>
export default {
    name: 'UserHome'
};
</script>

<style scoped>

</style>
